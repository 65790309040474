import React from 'react';
import { render } from 'react-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './styles.scss';

render(
    <>
        <Header />
        <Footer />
    </>,
    document.querySelector('#root')
);
