import React from 'react';

const Footer = () => {
    const companyName = '株式会社エーテック';
    const postalCode = '〒370-0424';
    const address = '群馬県太田市出塚町409-3';
    const tel = 'TEL:0276-52-6061';
    const fax = 'FAX:0276-52-6062';

    return (
        <footer className="footer">
            <address>
                <h3>{ companyName }</h3>
                <p>{ postalCode } { address }</p>
                <p>{ tel } { fax }</p>
            </address>
        </footer>
    );
};

export default Footer;
