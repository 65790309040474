import React from 'react';

const Header = () => {
    const heading = 'ページが見つかりません';
    const paragraph = 'このホームページは現在非公開となっております。';

    return (
        <header className="header">
            <h1>{ heading }</h1>
            <p>{ paragraph }</p>
        </header>
    );
};

export default Header;
